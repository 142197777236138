import React from 'react';
import TextWidget from '../Widget/TextWidget';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import SocialWidget from '../Widget/SocialWidget';
import MenuWidget from '../Widget/MenuWidget';

const menuDataTwo = [
  {title: 'About Us', href: '/about' },
  {title: 'Our Services', href: '/services' },
  { title: 'FAQ', href: '/faq' },
  { title: 'Portal', href: 'https://starbright.clientsecure.me/sign-in' },
  { title: 'Careers', href: '/about#cta-section' },
 
];
const menuDataThree = [
  { title: 'Contact Us', href: '/contact' },
  { title: 'Privacy Policy', href: '/privacy' },
  { title: 'Terms and Conditions', href: '/terms' },
];

export default function FooterStyle4() {
  return (
    <footer className="cs_footer cs_style_2 cs_type_1 cs_accent_bg cs_white_color">
      <div className="container">
        {/* <NewsletterStyle4
          title="Be Our Subscribers"
          subTitle="Join our newsletter to keep up to date <br />health from our experts"
        /> */}
        <div className="cs_footer_in">
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <TextWidget
                logoUrl="/images/StarBright_White.svg"
                text=""
              />
              <ContactInfoWidget />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <img src="/images/Member Logo HorizontalCASP.jpg" alt="CASP - The Council of Autism Service Providers Member" />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataTwo} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataThree} />
              <SocialWidget />
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom">
        <div className="container">
          <div className="cs_copyright">
            Copyright © 2025 Starbright Applied Behavioral Analyst, PLLC. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
