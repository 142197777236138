import React from 'react';
import Hero from '../Hero';
import BrandsSectionStyle2 from '../Section/BrandsSection/BrandsSectionStyle2';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import Section from '../Section';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import DepartmentSectionStyle3 from '../Section/DepartmentSection/DepartmentSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import WorkingProcess from '../Section/WorkingProcess';
import AnnouncementPopup from '../Announcements';

const featureListData = [
  {
    title: 'Collaborative Care',
    subTitle:
      'We work hand-in-hand with parents and families to create the best path forward for your child. By partnering closely with you, we ensure every step is aligned with your child\'s needs, strengths, and goals.',
    iconUrl: 'images/icons/charity_4300622.svg',
  },
  {
    title: 'Meaningful Progress',
    subTitle:
      'We focus on achieving real, meaningful outcomes for your child. It\'s not just about checking off boxes — it\'s about developing skills that will truly make a difference in your child\'s life.',
    iconUrl: 'images/icons/growth_8091262.svg',
  },
  {
    title: 'Engaging Learning',
    subTitle:
      ' Learning should be fun! Our programs are designed to keep children engaged and motivated, making their learning experience enjoyable and full of positive energy.',
    iconUrl: 'images/icons/playtime_1702240.svg',
  },
  {
    title: 'Evidence-Based Excellence',
    subTitle:
      'Our approach is grounded in the latest research and proven strategies. Every plan is carefully crafted based on evidence, ensuring your child receives the best possible support tailored to their unique needs.',
    iconUrl: 'images/icons/research_4599630.svg',
  },
];
const brandData = [
  { imgUrl: 'images/insurance/aetna-final.png', imgAlt: 'Aetna' },
  { imgUrl: 'images/insurance/bcbs-final.png', imgAlt: 'Blue Cross Blue Shield' },
  { imgUrl: 'images/insurance/cdphp-final.png', imgAlt: 'CDPHP' },
  { imgUrl: 'images/insurance/childhealth-final.png', imgAlt: 'Child Health Plus' },
  { imgUrl: 'images/insurance/cigna-final.png', imgAlt: 'Cigna' },
  { imgUrl: 'images/insurance/empireplan-final.png', imgAlt: 'EmpirePlan' },
  { imgUrl: 'images/insurance/mvp-final.png', imgAlt: 'MVP' },
  { imgUrl: 'images/insurance/tricare-final.png', imgAlt: 'TriCare' },
  { imgUrl: 'images/insurance/unitedcare-final.png', imgAlt: 'United Healthcare' },
];



const departmentData = [
  {
    iconUrl: 'images/departments/hands_7434695.svg',
    title: 'Early Intensive Behavioral Intervention',
    subTitle:
      'Comprehensive ABA Program for children (18 mo-6 years) in our specialized learning center.',
    href: '/services#eibi',
  },
  {
    iconUrl: 'images/departments/relations_11231498.svg',
    title: 'Social Skills <br/>Groups<br/> <br/> ',
    subTitle:
      'Group learning environment where social skills are taught and practiced.<br/> <br/> ',
    href: '/services#social-skills',
  },
  {
    iconUrl: 'images/departments/focus_4185159.svg',
    title: 'Focused Programs<br/> <br/> <br/> ',
    subTitle:
      'Targeted programs to work on individualized high-priority skills.<br/> <br/> ',
    href: '/services#focused-programs',
  },
];

const workingProcessData = [
  {
    title: 'Fill Out Inquiry Form',
    subTitle:
      'Click on the dark blue "Contact Us" button on the top of the page and fill out the Inquiry Form.',
    iconUrl: '/images/icons/assigment_14274378.svg',
    number: '01',
  },
  {
    title: 'We\'ll Call You',
    subTitle:
      'We\'ll call you within 2-3 business days. After you submit the form, our team will reach out to have an initial conversation with you. During this call, we’ll discuss your child\'s needs and confirm your interest in moving forward. We’ll also get your consent to check your insurance coverage and confirm eligibility for our services.',
    iconUrl: '/images/home_2/wording_process_icon_2.svg',
    number: '02',
  },
  {
    title: 'We Email You the Results <br />of the Benefit Check',
    subTitle:
      'Once we’ve completed the insurance review, we’ll send you an email with the results. This will include details about what your insurance covers and any out-of-pocket costs or responsibilities you may have.',
    iconUrl: '/images/home_2/wording_process_icon_3.svg',
    number: '03',
  },
  {
    title: 'Fill Out Intake Forms',
    subTitle:
      'We\'ll send you all of the required consent and intake forms to your client portal, as well as our Caregiver Handbook. Once you have filled evrything out, our office will work on obtaining prior authorization from your',
    iconUrl: '/images/home_2/wording_process_icon_4.svg',
    number: '04',
  },
  {
    title: 'Setup Intake Appointments',
    subTitle:
      'Once we receive insurance approval to conduct the initial assessment, we will reach out to schedule your intake appointments (minimum of 2 meetings).',
    iconUrl: '/images/icons/calendar_white.svg',
    number: '05',
  },
  {
    title: 'Initial Assessment',
    subTitle:
      'The initial assessment will take place in two parts: the first at your home and the second at our center. This is a great opportunity for caregivers to ask any questions and for your child\'s behavior analyst to observe and assess your child\'s strengths and needs in different environments.',
    iconUrl: '/images/icons/conversation_3050431.png',
    number: '06',
  },
  {
    title: 'Review Treatment Plan',
    subTitle:
      'Your child’s behavior analyst will create a personalized treatment plan that builds on their strengths and addresses their unique needs from the initial assessment. We’ll walk through the plan together, discuss priorities, and make sure it feels right for your family. Once we agree, we’ll submit it to your insurance for final approval before starting services.',
    iconUrl: '/images/icons/map.png',
    number: '07',
  },
];
export default function Home() {
  pageTitle('Home');
  return (
    <>
       
      <AnnouncementPopup />

      <Hero
        title="Guiding your child toward their brightest potential"
        subTitle="A brighter future starts with compassionate ABA therapy for meaningful progress and lasting change."
        bgUrl="/images/home_1/hero_bg_1.jpeg"
        imgUrl="/images/happy-kids-in-the-park.png"
        videoBtnText="About the Process"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: 'Phone',
            subTitle: ' 845-320-2855',
            iconUrl: '/images/contact/icon_1.svg',
            type:'phone'
          },
          {
            title: 'Fax',
            subTitle: '845-395-9296',
            iconUrl: '/images/icons/fax.svg',
          },
          {
            title: 'Location',
            subTitle: '118 River Rd # 14',
            subTitle2: 'Harriman, NY  10926',
            iconUrl: '/images/icons/pin.svg',
            type:'address'
          },
        ]}
        btnText2="Contact Us"
        btnUrl2="/contact"
      />
      {/* Start Brand Section */}
      <Section
        topMd={200}
        topLg={75}
        topXl={55}
        bottomMd={200}
        bottomLg={75}
        bottomXl={55}
      >
        <BrandsSectionStyle2 data={brandData} sectionTitle="Insurances We Work With" btnText="Don't See Your Insurance?" href="#faq2"/>
      </Section>
       {/* Start Features Section */}
      <Section topMd={175} topLg={75} topXl={45} bottomMd={100} bottomLg={55}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="images/students-playing-game-with-their-teacher.jpg"
          data={featureListData}
        />
      </Section>
      {/* End Features Section */}
      {/* Start Services Section */}
      <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/happy-encouraging-teacher-speaking-to-her-class-y-2024-03-01-17-32-00-utc.jpg"
        title="Our<br>Services"
        subTitle="At StarBright, we believe in personalized, evidence-based care. Our services include EIBI, social skills groups, and focused programs, all designed to help your child grow with confidence, learning, and joy."
      />
      <Section bottomMd={70} bottomLg={50} bottomXl={30}>
        <DepartmentSectionStyle3 data={departmentData} />
      </Section>
      
      {/* End Services Section */}
      {/* Start Process Section */}
      <Section
        topMd={90}
        topLg={70}
        topXl={50}
        bottomMd={190} 
        bottomLg={105} 
        bottomXl={105}
      >
        <WorkingProcess
          sectionTitle="The Process"
          sectionTitleUp=""
          sectionTitleDown="How Do I Enroll My Child?"
          sectionSubTitle=""
          data={workingProcessData}
        />
      </Section>
      {/* End Process Section */}
      {/* Start FAQ Section */}
      {/* <Section topMd={190} topLg={145} topXl={105} bottomMd={190} bottomLg={105} bottomXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Frequently Asked Questions"
          sectionTitleUp="FAQ"
        />
      </Section> */}
      {/* End FAQ Section */}
      
    </>
  );
}
