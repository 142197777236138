import React, { useState, useEffect } from "react";
import axios from "axios";

const AnnouncementPopup = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false); // Hide the popup when the close button is clicked
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sheetId = "13EoiyKR6KvqaghMylwweepLaUBtkkHdQwiXjl13OnUs"; // Replace with your Google Sheet ID
        const sheetName = "Sheet1"; // Replace with your sheet name (tab name)
        const apiKey = "AIzaSyBg-9Wtvxp_F9eS01-ju2T0ggAqOgLkr0Y"; // Replace with your API key
        const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${apiKey}`;

        const response = await axios.get(url);
        const rows = response.data.values.slice(1).map((row) => ({
          date: row[0], // Assuming the first column is "Date"
          announcement: row[1], // Assuming the second column is "Announcement"
        }));
        setAnnouncements(rows);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchData();
  }, []);

  if (!isVisible || announcements.length === 0) return null; // Do not render if no announcements or popup is closed

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <button onClick={handleClose} style={styles.closeButton}>
          ✖
        </button>
        <h2 style={styles.title}>Latest Announcements</h2>
        <ul style={styles.list}>
          {announcements.map((item, index) => (
            <li key={index} style={styles.listItem}>
              <strong>{item.date}:</strong> {item.announcement}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    maxWidth: "500px",
    width: "90%",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
  },
  title: {
    margin: "0 0 20px",
    fontSize: "24px",
    color: "#333",
  },
  list: {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  listItem: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "10px",
  },
};

export default AnnouncementPopup;
