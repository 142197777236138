import React from 'react';
import { Outlet } from 'react-router-dom';
import FooterStyle4 from '../Footer/FooterStyle4';
import HeaderStyle2 from '../Header/HeaderStyle2';


export default function Layout() {
  return (
    <>
      <HeaderStyle2
        logoSrc="/images/logosb.svg"
        variant="cs_heading_color cs_color_2"
        btnText="Contact Us"
        btnUrl="/contact"
      />
      <Outlet />
      <FooterStyle4 />
    </>
  );
}
